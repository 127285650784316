<template>
    <section class="tables new-updated-design">
        <vue-snotify></vue-snotify>
        <div>
            <b-modal id="modal" :title="referralNote" ref="referral-modal">
                <div class="row">
                    <div class="col-md-12">
                        <p class="mb-3">{{ modalContent }}</p>
                    </div>
                </div>
            </b-modal>
        </div>
        <div class="row">
            <div class="col-lg-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ $t('properties_list.View_Properties') }} </h4>
                    </div>
                    <div class="card-body">
                        <vue-good-table mode="remote" @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange" :totalRows="totalRecords" :pagination-options="{
                                enabled: true, dropdownAllowAll: false, perPageDropdown: [10, 20, 50, 100, 200], nextLabel: this.$t('pagination.next'),
                                prevLabel: this.$t('pagination.Previous'),
                                rowsPerPageLabel: this.$t('pagination.rowsPerPage'),
                                ofLabel: this.$t('pagination.of'),
                            }" :rows="rows" :columns="columns" :sort-options="{
                                enabled: false,
                            }">
                            <template slot="table-row" slot-scope="props">

                                <span v-if="props.column.field == 'property_type'">
                                    <span>{{ $t('property_detail.' + props.row.property_type) }}</span>
                                </span>
                                <span v-if="props.column.field == 'city'">
                                    <span> {{ props.row["city" + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'state'">
                                    <span>{{ props.row["state" + currentLanguage] }}</span>
                                </span>
                                <span v-if="props.column.field == 'status'">
                                    <span v-if="props.column.field === 'status' && props.row.approve === 1">
                                        Approved
                                    </span>
                                    <span v-else>
                                        Not Approved
                                        <b-button size="sm" @click="approveProperty(props.row.id)"
                                            class="mr-1 btn-info text-white">
                                            Approve
                                        </b-button>
                                    </span>
                                </span>
                                <span v-if="props.column.field === 'action'" class="text-nowrap">

                                    <b-button size="sm" @click="propertyDetail(props.row.id)"
                                        class="mr-1 btn-info text-white">
                                        {{ $t('properties_list.Detail') }}
                                    </b-button>
                                    <b-button size="sm" @click="deleteProperty(props.row.id)"
                                        class="mr-1 btn-danger text-white">
                                        {{ $t('properties_list.Delete') }}
                                    </b-button>
                                </span>
                            </template>
                        </vue-good-table>
                    </div>
                </div>
            </div>
        </div>
        <div></div>
    </section>
</template>

<script>
import Vue, { set } from "vue";
import SortedTablePlugin from "vue-sorted-table";
import API from '@/api'
import moment from 'moment'

Vue.use(SortedTablePlugin, {
    ascIcon: '<i class="ti-arrow-down"></i>',
    descIcon: '<i class="ti-arrow-up"></i>'
});
export default {
    name: 'Spotters',
    data() {
        return {
            searchTime: null,
            isDisabled: false,
            referralNote: 'Referral Note',
            modalContent: '',
            selected: true,
            unSelected: false,
            currentLanguage: '',
            serverParams: {
                search: "",
                sort: [
                    {
                        field: 'UserID', // example: 'name'
                        type: 'desc' // 'asc' or 'desc'
                    }
                ],
                page: 1, // what page I want to show
                perPage: 10 // how many items I'm showing per page
            },
            columns: [
                {
                    label: 'Property Type',
                    field: 'property_type',
                    filterable: false,
                },
                {
                    label: 'City',
                    field: 'city',
                    sortable: false,
                },
                {
                    label: 'State',
                    field: "state",
                    sortable: false,
                },
                {
                    label: 'Status',
                    field: "status",
                    sortable: false,
                },
                {
                    label: 'Action',
                    field: "action",
                    sortable: false,
                },
            ],
            isLoading: false,
            rows: [],
            totalRecords: 0,
            spots: [],
            ReferredBy: new Map(),
            form: {
                ReferredID: '',
            },
        };
    },
    methods: {
        approveProperty(id) {
            API.approveProperty(id, res => {
                if (res.status == 200) { 
                    this.loadItems();
                    this.$swal({
                        type: 'success',
                        title: 'Success',
                        text: res.data.message, 
                    })
                } else {
                    this.$swal({
                        type: 'error',
                        title: 'Oops...',
                        text: res.message,
                    })
                }
            }, err => {
                console.log(err);
            })
        },
        updateColumnLabels() {
            this.columns.forEach(col => {
                switch (col.field) {
                    case 'property_type':
                        col.label = this.$t('properties_list.Property_Type');
                        break;
                    case 'city':
                        col.label = this.$t('properties_list.city');
                        break;
                    case 'state':
                        col.label = this.$t('properties_list.state');
                        break;
                    case 'action':
                        col.label = this.$t('properties_list.action');
                        break;
                    default:
                        break;
                }
            });
        },
        onLanguageChanged(language) {
            if (language == 'en') {
                this.currentLanguage = '';
            } else {
                this.currentLanguage = '_' + language;
            }

        },
        propertyDetail(id) {
            this.$router.push({ name: 'properties_detail', params: { id: id } });
        },
        deleteProperty(id) {
            this.$swal({
                title: this.$t('sweetalert.Are_you_sure?'),
                text: this.$t('sweetalert.no_revert'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: this.$t('sweetalert.yes'),
                cancelButtonText: this.$t('sweetalert.cancel')
            }).then((result) => {
                if (result.value == true) {
                    API.deleteProperty(id, res => {
                        this.loadItems();
                    }, err => {
                        console.log(err);
                    })
                }
            })
        },
        openReferralModal(row) {
            this.modalContent = row.ReferralNotes;
            this.$refs['referral-modal'].show();
        },
        editMemberStatuss() {
            API.updateRefMemberStatus(this.form,
                data => {
                    this.isDisabled = false;
                    if (data.status === 200) {
                        this.$showNotification(data, "success")
                        this.loadItems();
                    } else {
                        this.errorMsg('Please try again.')
                    }
                },
                err => {
                    this.isDisabled = false;
                    this.errorMsg(err)
                }
            )
        },
        dateFormat(val) {
            return moment(val).format('DD MMMM YYYY')
        },
        loadItems() {
            API.getAllProperties(this.serverParams,
                data => {
                    this.totalRecords = data.totalRecords;
                    this.rows = data.data;
                    this.isLoading = false;
                }, err => {
                    console.log(err)
                    this.isLoading = false;
                })
        },
        updateParams(newProps) {
            this.serverParams = Object.assign({}, this.serverParams, newProps);
        },
        onPageChange(params) {
            this.updateParams({ page: params.currentPage });
            this.loadItems();
        },
        onPerPageChange(params) {
            this.updateParams({ perPage: params.currentPerPage });
            this.loadItems();
        },
        errorMsg(err) {
            console.log(err);
            this.$showNotification({ message: 'Failed system error' }, "error")
        }
    },
    computed: {
        propertyTypeLabel() {
            return this.$t('properties_list.Property_Type');
        },
        propertycityLabel() {
            return this.$t('properties_list.city');
        },
        propertystateLabel() {
            return this.$t('properties_list.state');
        },
        propertyactionLabel() {
            return this.$t('properties_list.action');
        },
    },
    mounted() {
        this.isLoading = true;
        Vue.prototype.$EventBus.$on('changeLanguage', this.onLanguageChanged);
        Vue.prototype.$EventBus.$on('changeLanguage', this.updateColumnLabels);
        this.loadItems();
        this.updateColumnLabels();
    },
    watch: {
        '$i18n.locale': function (newLocale, oldLocale) {
            this.columns.find(col => col.field === 'property_type').label = this.propertyTypeLabel;
            this.columns.find(col => col.field === 'city').label = this.propertycityLabel;
            this.columns.find(col => col.field === 'state').label = this.propertystateLabel;
            this.columns.find(col => col.field === 'action').label = this.propertyactionLabel;

        }
    }
}
</script>